<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>


        <div class="row cont_pagamento cont_tickets">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:59px">
                                <thead>
                                    <tr>



                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class='all'
                                            style='padding-top:5px!important;;padding-bottom:5px!important;text-align:center!important'>
                                            Tipologia</th>


                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            Descrizione</th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            Azienda</th>


                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            Utente</th>

                                        <th class='all'
                                            style='padding-top:5px!important;;padding-bottom:5px!important;'>Inserito Il
                                        </th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>
                                            Scade Il</th>

                                        <th class='all'
                                            style='padding-top:5px!important;;padding-bottom:5px!important;text-align:center!important'>
                                            Scaduto</th>


                                        <th class='all'
                                            style='padding-top:5px!important;;padding-bottom:5px!important;text-align:right'>
                                            Da Pagare</th>

                                        <th class='all'
                                            style='padding-top:5px!important;;padding-bottom:5px!important;text-align:right'>
                                            Pagato</th>

                                        <th class='all' style='padding-top:5px!important;;padding-bottom:5px!important'>
                                            <div class="spazio">Data Pag.</div>
                                        </th>

                                        <th class='all'
                                            style='padding-top:5px!important;;padding-bottom:5px!important;text-align:right'>
                                            Riman.</th>

                                        <th class='all'
                                            style='padding-top:5px!important;;padding-bottom:5px!important;text-align:center!important'>
                                            Stato</th>

                                        <th class='all'
                                            style='padding-top:5px!important;;padding-bottom:5px!important;text-align:center!important'>
                                            Allegato</th>

                                        <th v-if="isMobile" class='desktop tdPayments'
                                            style='padding-top:5px!important;;padding-bottom:5px!important'>Pagamenti
                                        </th>

                                        <th class='all'
                                            style="padding-top:5px!important;;padding-bottom:5px!important;">
                                        </th>

                                    </tr>
                                </thead>

                                <tfoot>


                                    <tr>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>
                                            <div class="div_n">&nbsp;</div>
                                        </th>

                                        <th class='all col_footer'>Totale (€)</th>

                                        <th class='all col_footer'>&nbsp;</th>


                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer_right' id="v_tot_importo">0,00</th>

                                        <th class='all col_footer_right' id="v_tot_importo_pagato">0,00</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer_right' id="v_tot_importo_rimanente">0,00</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th class='all col_footer'>&nbsp;</th>

                                        <th v-if="isMobile" class='desktop tdPayments'
                                            style='padding-top:15px!important;;padding-bottom:5px!important;text-align:center!important;border-top:1px solid black'>
                                            &nbsp;</th>

                                        <th class='all col_footer'></th>

                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetAziende = !sheetAziende">

                                    <v-img alt="Proprietario Logo" class="  " contain
                                        src="@/assets/btn_cancel3_alertduedate.png" max-width="24" max-height="24"
                                        style="margin-right:13px" transition="scale-transition" />

                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">

                                        <v-icon aria-hidden="false">mdi-domain</v-icon>

                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>

                                    </v-list-item>

                                    <div style="min-height:170px;height:170px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpagamenti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetPagamenti" class="vpagamentisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetPagamenti" fixed style="position:fixed;">
                                Pagamenti

                                <v-btn class='closeSheet align_close_sheet' icon
                                    @click="sheetPagamenti = !sheetPagamenti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchPagamenti"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredPagamenti" :key="item.id"
                                        @click="manageClickPagamenti(item)">

                                        <v-img alt="Pagamenti Logo" class="  " contain
                                            src="@/assets/filter_type_payment.png" max-width="24" max-height="24"
                                            transition="scale-transition" />

                                        <v-list-item-title class='itemPagamento'>{{ item.nome }}</v-list-item-title>
                                        <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline
                                        </v-icon>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contscaduti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetScaduti" class="vscadutisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetScaduti" fixed style="position:fixed;">
                                Scaduti
                                <v-btn class='closeSheet align_close_sheet' icon @click="sheetScaduti = !sheetScaduti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchScaduti"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredScaduti" :key="item.id"
                                        @click="manageClickScaduti(item)">

                                        <v-img alt="Scaduti Logo" class="  " contain src="@/assets/filter_expired.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemScaduto'>{{ item.nome }}</v-list-item-title>
                                        <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline
                                        </v-icon>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


        <div class="contpopuppagamento" style="display:none">

            <template>
                <modal name="popupPagamento" :clickToClose="false" :width="300" :height="470">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Pagamento

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div id="d_importo_rimanente"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;">Rimanente</label>

                                            <vue-autonumeric v-model="pagamento.importo_rimanente" disabled :options="{
                                                digitGroupSeparator: '.',
                                                decimalCharacter: ',',
                                                decimalCharacterAlternative: '.',
                                                currencySymbol: '\u00a0€',
                                                currencySymbolPlacement: 's',
                                                roundingMethod: 'U',
                                                minimumValue: '0'
                                            }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div id="d_pagato"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;">Importo</label>

                                            <vue-autonumeric id="fldImportoPagato" v-model="pagamento.importo_pagato"
                                                :options="{
                                                    digitGroupSeparator: '.',
                                                    decimalCharacter: ',',
                                                    decimalCharacterAlternative: '.',
                                                    currencySymbol: '\u00a0€',
                                                    currencySymbolPlacement: 's',
                                                    roundingMethod: 'U',
                                                    minimumValue: '0'
                                                }"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div>

                                <v-menu v-model="showPicker2" :close-on-content-click="false"
                                    transitions="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="pagamento.data_pagamento" label="Data" persistent-hint
                                            readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="pagamento.data_pagamento" no-title
                                        @input="showPicker2 = false" locale="it"></v-date-picker>
                                </v-menu>

                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">




                                <v-img alt="" class="btn-cancel-pagamento" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="35" max-height="35"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelPagamento" />

                                <v-img alt="" class="btn-reset-pagamento" contain src="@/assets/reset.png"
                                    max-width="25" max-height="25"
                                    style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Pulisci" @click="btnResetPagamento" />


                                <v-img alt="" class="btn-confirm-pagamento" contain
                                    src="@/assets/btn_confirm3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmPagamento" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="409">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Filtri

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="Cerca testo">
                            </v-text-field>

                        </v-col>

                    </v-row>


                    <v-row
                        style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                            <div id="cont_owner" style="cursor:pointer">
                                <ion-item id="itemOwner"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                        position="floating">Proprietario </ion-label>

                                    <ion-select id="proprietario" name="proprietario" class='proprietario'
                                        cancel-Text="Chiudi" done-Text="" :value="filterProprietario"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in proprietari" :key="item.login"
                                            :value='item.login'> {{
                                                item.name }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row
                        style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                            <div id="cont_tipi_pag" style="cursor:pointer">
                                <ion-item id="itemTipiPag"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                        position="floating">Tipi Pagamento </ion-label>

                                    <ion-select id="tipopagamento" name="tipopagamento" class='tipopagamento'
                                        cancel-Text="Chiudi" done-Text="" :value="filterTipoPagamento"
                                        interface="action-sheet">

                                        <ion-select-option v-for="item in tipipagamento" :key="item.id"
                                            :value='item.id'> {{
                                                item.description }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>


                    <v-row
                        style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                            <div id="cont_scaduti" style="cursor:pointer">
                                <ion-item id="itemScaduti"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                        position="floating">Scaduti </ion-label>

                                    <ion-select id="scaduti" name="scaduti" class='scaduti' cancel-Text="Chiudi"
                                        done-Text="" :value="filterScaduti" interface="action-sheet">

                                        <ion-select-option v-for="item in scaduti" :key="item.id" :value='item.id'> {{
                                            item.description }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row
                        style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                        <v-col cols="12" md="12"
                            style="margin-bottom: 0px!important;padding-bottom: 0px!important;margin-top:0px!important;padding-top: 0px!important">

                            <div id="cont_tipologia" style="cursor:pointer">
                                <ion-item id="itemTipologia"
                                    style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                                    <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                        position="floating">Tipologia </ion-label>

                                    <ion-select id="tipologia" name="tipologia" class='tipologia' cancel-Text="Chiudi"
                                        done-Text="" :value="filterTipologia" interface="action-sheet">

                                        <ion-select-option v-for="item in tipologia" :key="item.id" :value='item.id'> {{
                                            item.nome }}</ion-select-option>

                                    </ion-select>
                                </ion-item>
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-delete-search" contain src="@/assets/reset-alertduedate.png"
                                    max-width="35" max-height="35" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Elimina Filtri" @click="btnResetSearch" />

                                <v-img alt="" class="btn-confirm-search" contain
                                    src="@/assets/btn_confirm3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="text-left">
            <v-bottom-sheet v-model="sheetTipologia">
                <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                    <v-btn class='closeStatusSheet' icon @click="sheetTipologia = !sheetTipologia">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                    <div class="py-3">
                        Tipologia
                    </div>
                    <v-list-item v-for="tile in tipologia" :key="tile.id" @click="filterTipologia(tile.id)">
                        <v-list-item-title style="justify-content: center;text-align: left;">{{
                            tile.nome
                        }}</v-list-item-title>
                    </v-list-item>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <div class="contproprietari">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetProprietari" class="vproprietarisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetProprietari" fixed style="position:fixed;">
                                Proprietari
                                <v-btn class='closeSheet' icon @click="sheetProprietari = !sheetProprietari">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchProprietari"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredProprietari" :key="item.login"
                                        @click="manageClickProprietario(item)">

                                        <v-img alt="Proprietario Logo" class="  " contain src="@/assets/owners.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemProprietario'>{{ item.name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contpopupmails" style="display:none">

            <template>
                <modal name="popupMails" :clickToClose="false" :width="362" :height="462">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px;display:flex">

                            <div>
                                Mail per invio notifica
                            </div>

                        </v-col>



                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="searchMail"
                                style="width:400px">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display:flex">

                            <div style="margin-left:10px;width:50px">&nbsp;</div>
                            <div style="font-weight:bold;width:200px">E-Mail</div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-top:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">


                            <v-list style="overflow-y:auto;max-height: 233px;min-height: 233px;">

                                <v-list-tile v-for="el in filteredMails" :key="el.email">

                                    <div class="contSecCheck"
                                        style="display:flex!important;height:30px;align-items:center;">

                                        <div style="margin-left:10px;width:50px;margin-top:8px">
                                            <v-checkbox v-model="mailSel" multiple class="cl-checkbox selsec"
                                                :value="el.email" />

                                        </div>

                                        <div style="width:200px">
                                            {{ el.email }}
                                        </div>


                                    </div>

                                </v-list-tile>


                            </v-list>


                        </v-col>


                    </v-row>





                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-return-payment" contain
                                    src="@/assets/ins_edit_pagamenti_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Pagamento" @click="btnReturnPayment" />

                                <v-img alt="" class="btn-cancel-mails" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelMails" />



                                <v-img alt="" class="btn-confirm-mails" contain
                                    src="@/assets/btn_confirm3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Conferma"
                                    @click="btnConfirmMails" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupcalculator" style="display:none">

            <template>
                <modal name="popupCalculator" :clickToClose="false" :width="350" :height="226">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-left:10px;margin-top:10px!important;font-weight:bold">

                            Totale Pagamenti

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="4" md="4">
                            <div style="margin-left:10px">
                                Pag. Selezionati
                            </div>
                        </v-col>

                        <v-col cols="8" md="8">

                            <div id="divFattSel"
                                style="width:100%;min-width:220px;max-width:220px;height:70px;text-align:right;overflow-y:auto;font-weight:bold;font-size: 8px;">
                            </div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="4" md="4">
                            <div style="margin-left:10px">
                                Totale Rim. (€)
                            </div>
                        </v-col>

                        <v-col cols="8" md="8">

                            <div id="divTotSel"
                                style="font-weight:bold;width:100%;min-width:220px;max-width:220px;text-align:right">
                            </div>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">


                                <v-img alt="" class="btn-cancel-calculator" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Chiudi"
                                    @click="btnCancelCalculator" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupusersnotifiche" style="display:none">

            <template>
                <modal name="popupUsersNotifiche" :clickToClose="false" :width="362" :height="462">


                    <v-row>

                        <v-col cols="12" md="12"
                            style="margin-top:10px!important;font-weight:bold;margin-left:10px;display:flex">

                            <div>
                                Utenti per invio notifica app
                            </div>

                        </v-col>



                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="searchUsers"
                                style="width:400px">
                            </v-text-field>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display:flex">

                            <div style="margin-left:10px;width:50px">&nbsp;</div>
                            <div style="font-weight:bold;width:200px">Utente</div>

                        </v-col>

                    </v-row>


                    <v-row style="margin-top:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">


                            <v-list style="overflow-y:auto;max-height: 233px;min-height: 233px;">

                                <v-list-tile v-for="el in filteredUsers" :key="el.login">

                                    <div class="contSecCheck"
                                        style="display:flex!important;height:30px;align-items:center;">

                                        <div style="margin-left:10px;width:50px;margin-top:8px">
                                            <v-checkbox v-model="usersSel" multiple class="cl-checkbox selsec"
                                                :value="el.login" />

                                        </div>

                                        <div style="width:200px">
                                            {{ el.name }}
                                        </div>


                                    </div>

                                </v-list-tile>


                            </v-list>


                        </v-col>


                    </v-row>





                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-return-mails" contain
                                    src="@/assets/gruppo-mail-alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Mails Notifiche" @click="btnReturnMails" />

                                <v-img alt="" class="btn-cancel-users" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelUsers" />

                                <v-img alt="" class="btn-confirm-users" contain src="@/assets/save-alertduedate.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Salva" @click="btnConfirmUsers" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>



        <div class="contpopupviewfilepdf" style="display:none">

            <template>
                <modal name="popupViewFilePdf" id="pViewFilePdf" :clickToClose="false" :width="1651" :height="600">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Vedi Allegato

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;">

                            <div style="width: 94%;height: 50vh;overflow-y: auto;overflow-x: auto;">

                                <div id="container-view-pdf"></div>

                            </div>


                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-otp-delete" contain
                                    src="@/assets/btn_cancel3_alertduedate.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelViewFilePdf" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>





    </div>
</template>

<script>
import apipagamento from "../utils/pagamento/apipagamento";
import axios from 'axios';
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";
import router from ".././router";

import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';



export default {

    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {

        /*eslint-disable no-undef*/


        this.$root.$children[0].checkLogout();


        var pointerVue = this;

        window.curSrcText = "";

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        var isPhoneApp = params.isPhoneApp;

        console.log(isPhoneApp);

        this.aziende = JSON.parse(localStorage.getItem('al_info_global')).filtro_aziende;

        this.filtro_tipologie_pagamento = JSON.parse(localStorage.getItem('al_info_global')).filtro_tipologie_pagamento;

        this.filtro_scaduti = JSON.parse(localStorage.getItem('al_info_global')).filtro_scaduti;


        console.log("TIPES PAG: ", JSON.parse(localStorage.getItem('al_info_global')).filtro_tipes_pag);

        this.tipologia = JSON.parse(localStorage.getItem('al_info_global')).filtro_tipes_pag;





        this.search = "";
        this.searchPagamenti = "";
        this.searchScaduti = "";

        //window.curAzienda = "-1";



        window.curPagamento = "";
        window.curScaduto = "-1";
        window.curTipologia = "-1";
        window.propid = "-1";

        window.curChooseSubject = window.$cookies.get("sel_tipo_soggetto_pagamento");

        if (window.curChooseSubject == "P") {

            window.curAzienda = "-1";

        }

        if (window.curChooseSubject == "A") {

            window.curAzienda = window.$cookies.get("sel_filter_azienda_pagamenti");

        }


        pointerVue.filterTipologia = "-1";
        pointerVue.filterScaduti = "-1";
        pointerVue.filterTipoPagamento = "3";
        pointerVue.filterProprietario = "-1";


        $(document).ready(
            function () {


            }
        );

        $(window).resize(function () {

            pointerVue.centerPopupViewFilePdf();

        });


        $(document).ready(function () {


            $('body').on('ionChange', '.proprietario', function () {



                pointerVue.filterProprietario = this.value;

            });

            $('body').on('ionChange', '.tipopagamento', function () {



                pointerVue.filterTipoPagamento = this.value;

            });

            $('body').on('ionChange', '.scaduti', function () {



                pointerVue.filterScaduti = this.value;

            });


            $('body').on('ionChange', '.tipologia', function () {



                pointerVue.filterTipologia = this.value;

            });





            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });



        });

        setTimeout(() => {

            this.initFilters();

        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);


            var ids = "";

            switch (data) {

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":

                    router.push({
                        path: "/editPagamento/0"
                    });

                    break;

                case "btn_edit":

                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editPagamento/" + ids
                    });


                    break;

                case "btn_delete":

                    if (window.id_par_sel == 0) {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da eliminare",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }

                    if (window.id_par_sel > 0) {

                        this.deleteRow();

                    }

                    break;

                case "btn_copy":


                    ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/copyPagamento/" + ids
                    });

                    break;

                case "btn_back":

                    router.push({
                        path: "/filterPagamenti"
                    });

                    break;

                case "btn_ins_edit_payment":


                    this.syncPagamento();


                    break;

                case "btn_calculator":

                    this.calculator();

                    break;


                case "btn_vedi_doc":

                    this.vediAllegato();

                    break;


                default:
                    break;
            }
        });

        //this.setupButtons();

        this.setupButtonsGrid();

        this.setupGrid();

        // di default i pulsanti edit, delete e copy vengono nascosti
        this.hideShowButtons(false);

    },


    components: {
        VueAutonumeric,
    },



    data: () => ({

        filterTipologia: "",

        filterScaduti: "",

        filterTipoPagamento: "",

        filterProprietario: "",

        tipipagamento: [
            {
                id: "-1",
                description: "Tutti"
            },
            {
                id: "1",
                description: "Pagato"
            },
            {
                id: "2",
                description: "Pagato Parzialmente"
            },
            {
                id: "3",
                description: "Non Pagato"
            }

        ],

        scaduti: [
            {
                id: "-1",
                description: "Tutti"
            },
            {
                id: "1",
                description: "Scaduti"
            },
            {
                id: "2",
                description: "Non Scaduti"
            }

        ],

        pdfUrl: "",

        mailSel: [],

        usersSel: [],

        mails: [],

        users: [],

        searchMail: "",

        searchUsers: "",

        proprietari: [],

        sheetProprietari: false,
        searchProprietari: "",

        tipologia: [],

        sheetTipologia: false,

        textSearch: "",

        currentFile: null,
        showPicker: false,

        showPicker2: false,

        pagamento: {},

        sheetAziende: false,
        search: '',

        sheetPagamenti: false,
        searchPagamenti: '',

        aziende: [],

        sheetScaduti: false,
        searchScaduti: '',

        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,

        //  aziende: [],
        filtro_tipologie_pagamento: [],
        filtro_scaduti: [],

    }),

    computed: {

        filteredMails() {

            return _.orderBy(this.mails.filter(item => {
                if (!this.searchMail) return this.mails;
                return (item.email.toLowerCase().includes(this.searchMail.toLowerCase()));
            }), 'headline')
        },

        filteredUsers() {

            return _.orderBy(this.users.filter(item => {
                if (!this.searchUsers) return this.users;
                return (item.name.toLowerCase().includes(this.searchUsers.toLowerCase()));
            }), 'headline')
        },

        isMobile() {
            return true;
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredPagamenti() {
            return _.orderBy(this.filtro_tipologie_pagamento.filter(item => {
                if (!this.searchPagamenti) return this.filtro_tipologie_pagamento;
                return (item.nome.toLowerCase().includes(this.searchPagamenti.toLowerCase()));
            }), 'headline')
        },

        filteredScaduti() {
            return _.orderBy(this.filtro_scaduti.filter(item => {
                if (!this.searchScaduti) return this.filtro_scaduti;
                return (item.nome.toLowerCase().includes(this.searchScaduti.toLowerCase()));
            }), 'headline')
        },

        filteredProprietari() {

            return _.orderBy(this.proprietari.filter(item => {
                if (!this.searchProprietari) return this.proprietari;
                return (item.name.toLowerCase().includes(this.searchProprietari.toLowerCase()));
            }), 'headline')

        }


    },

    methods: {

        btnResetSearch: function () {

            this.textSearch = "";
            window.curSrcText = "";

            window.curPagamento = "-1";
            this.filterTipoPagamento = "-1";

            window.curScaduto = "-1";
            this.filterScaduti = "-1";

            window.curTipologia = "-1";
            this.filterTipologia = "-1";

            window.propid = "-1";
            this.filterProprietario = "-1";



            $('.selfiltri').attr('src', '/alertduedate/img/alertduedate-filtri-disattivi.png');

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);
        },


        centerPopupViewFilePdf: function () {


            var windowWidth = $(window).width();
            var windowHeight = $(window).height();


            var divWidth = Math.ceil((90 / 100) * windowWidth);
            var divHeight = Math.ceil((65 / 100) * windowHeight);


            var leftPosition = (windowWidth - divWidth) / 2;
            var topPosition = (windowHeight - divHeight) / 2;

            $('#pViewFilePdf').find('.vm--modal').css({
                'position': 'absolute',
                'left': leftPosition + 'px',
                'top': topPosition + 'px',
                'width': divWidth + 'px',
                'height': divHeight + 'px'
            });


        },




        reloadData: function () {

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            window.table.ajax.reload();
            this.hideShowButtons(false);

            setTimeout(() => {

                this.reloadData();

            }, 10000);
        },



        getWindowMobile: function () {

            if (window.innerWidth <= 768) {

                return 1;

            }
            else {

                return 0;

            }

        },

        vediAllegato: function () {

            var v_url = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    v_url = $(this).attr("v-url");

                }

            });

            var v_ext = v_url.split(".").pop();

            // console.log("EXT: " + v_ext);

            if (v_ext.toString().toUpperCase() == "PDF") {

                this.vediPdf(v_url);

            }





        },

        vediPdf: function (v_url) {

            var that = this;


            setTimeout(() => {


                $(".contpopupviewfilepdf").show();

                that.$modal.show('popupViewFilePdf');

                that.pdfUrl = v_url;

                setTimeout(() => {


                    that.loadPdf();

                    that.centerPopupViewFilePdf();

                }, 200);



            }, 100);





        },

        btnCancelViewFilePdf: function () {

            this.$modal.hide("popupViewFilePdf");

        },


        async loadPdf() {

            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

            const pdfContainer = document.getElementById('container-view-pdf');
            pdfContainer.innerHTML = '';

            try {
                const pdf = await pdfjsLib.getDocument(this.pdfUrl).promise;
                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1.5 });

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };

                    await page.render(renderContext).promise;

                    pdfContainer.appendChild(canvas);
                }
            } catch (error) {
                console.error('Error loading PDF: ', error);
            }
        },


        btnReturnMails: function () {

            this.$modal.hide('popupUsersNotifiche');

            $(".contpopupmails").show();

            this.$modal.show('popupMails');


        },

        btnCancelUsers: function () {

            this.$modal.hide('popupUsersNotifiche');



        },

        btnConfirmUsers: function () {

            var errore = "";

            var that = this;



            if (errore == "") {

                console.log("ok");


                that.saveData();




            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }


        },




        formatMoney: function (number, decPlaces, decSep, thouSep) {
            decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
                decSep = typeof decSep === "undefined" ? "," : decSep;
            thouSep = typeof thouSep === "undefined" ? "." : thouSep;
            var sign = number < 0 ? "-" : "";
            var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
            var j = (j = i.length) > 3 ? j % 3 : 0;

            return sign +
                (j ? i.substr(0, j) + thouSep : "") +
                i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
                (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");

        },

        btnCancelCalculator: function () {

            this.$modal.hide('popupCalculator');

        },


        calculator: function () {

            var list_fatt_sel = "";
            var tot_sub = 0;
            var tot_iva = 0;
            var tot = 0;

            console.log(tot_sub);
            console.log(tot_iva);
            console.log(tot);

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    var v_row = $(this).parent().parent();

                    var item = window.table.row(v_row).data();

                    console.log("ROM SELECTED: ", item);


                    if (list_fatt_sel.length > 0) {
                        list_fatt_sel = list_fatt_sel + "<br>";
                    }
                    list_fatt_sel = list_fatt_sel + "(" + item.calc_nome_tipologia + ") " + item.calc_descrizione;

                    // var val = item.calc_importo;
                    var val = item.calc_importo_rimanente;

                    console.log("IMPORTO: ", val);

                    // var v_imp = val.toString().replace(".", ",");

                    // console.log("IMPORTO 2: ", v_imp);

                    console.log("IMPORTO 3: ", parseFloat(val));


                    // v_imp = v_imp.toString().replace(",", ".");
                    tot = parseFloat(tot) + parseFloat(val);


                }

            });



            $(".contpopupcalculator").show();

            this.$modal.show('popupCalculator');

            setTimeout(() => {

                $("#divFattSel").html(list_fatt_sel);

                // $("#divSubSel").html(this.formatMoney(tot_sub));

                // $("#divIvaSel").html(this.formatMoney(tot_iva));

                $("#divTotSel").html(this.formatMoney(tot));


            }, 200);


        },

        btnReturnPayment: function () {

            this.$modal.hide('popupMails');

            $(".contpopuppagamento").show();

            this.$modal.show('popupPagamento');

        },

        btnCancelMails: function () {

            this.$modal.hide('popupMails');


        },


        btnConfirmMails: function () {

            var errore = "";

            var that = this;



            if (errore == "") {

                console.log("ok");



                setTimeout(() => {

                    that.$modal.hide('popupMails');

                    that.searchUsers = "";

                    $(".contpopupusersnotifiche").show();

                    that.$modal.show('popupUsersNotifiche');


                }, 200);



            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });


            }




        },



        btnCancelPagamento: function () {

            this.$modal.hide('popupPagamento');


        },

        btnResetPagamento: function () {

            this.pagamento.importo_pagato = null;
            this.pagamento.data_pagamento = null;
            $("#fldImportoPagato").val("");

        },

        btnConfirmPagamento: function () {

            var that = this;

            that.pagamento.is_child_pag = "S";

            console.log("PAGAMENTO: ", that.pagamento)

            var errore = "";

            if (that.pagamento.importo_pagato == null) {
                errore = "Specificare l'importo";
            }

            if (errore == "") {
                if (that.pagamento.importo_pagato == 0) {
                    errore = "Specificare l'importo";
                }
            }


            if (errore == "") {
                if (that.pagamento.data_pagamento == null) {
                    errore = "Specificare la data";
                }
            }

            if (errore == "") {
                if (parseFloat(that.pagamento.importo_pagato) > parseFloat(that.pagamento.importo_rimanente)) {

                    var v_rim = parseFloat(that.pagamento.importo_rimanente);

                    errore = "Il valore dell'importo non pùo essere superiore al valore rimanente da pagare di € " + v_rim.toFixed(2);

                }
            }


            if (errore == "") {

                console.log("ok");



                setTimeout(() => {

                    that.$modal.hide('popupPagamento');


                    that.searchMail = "";

                    $(".contpopupmails").show();

                    that.$modal.show('popupMails');


                }, 200);





            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 3000
                });

            }


        },



        manageClickProprietario: async function (proprietario) {

            console.log("PROPRIETARIO SEL: ", proprietario);

            this.sheetProprietari = false;

            window.propid = proprietario.login;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            window.table.ajax.reload();

        },



        filterTipologia: function (v_tipologia) {

            this.sheetTipologia = false;

            window.curTipologia = v_tipologia;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            window.table.ajax.reload();


        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            window.propid = this.filterProprietario;

            window.curPagamento = this.filterTipoPagamento;

            window.curScaduto = this.filterScaduti;

            window.curTipologia = this.filterTipologia;


            $('.selfiltri').attr('src', '/alertduedate/img/alertduedate-filtri-attivi.png');


            if ((window.curSrcText == "") && (window.propid == "-1") && (window.curPagamento == "-1") && (window.curScaduto == "-1") && (window.curTipologia == "-1")) {


                $('.selfiltri').attr('src', '/alertduedate/img/alertduedate-filtri-disattivi.png');
            }


            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);

        },



        initFilters: async function () {

            var v_token = window.$cookies.get("token");
            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.initFiltersGo(
                v_token,
                "alertduedate"
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from initFiltersGo", res);

                that.proprietari = res.data.result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        saveData: async function () {

            var v_choose2 = window.$cookies.get("sel_tipo_soggetto_pagamento");

            var v_token = window.$cookies.get("token");
            var that = this;

            var id_rec = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (id_rec != "") {
                        id_rec = id_rec + ",";
                    }
                    id_rec = id_rec + $(this).attr("data-id");
                }

            });


            console.log("TOKEN ", v_token);
            console.log("PAGAMENTO ", that.pagamento);
            console.log("ID_REC ", id_rec);
            console.log("TYPE_SUBJECT ", v_choose2);

            var strMails = "";

            for (var x = 0; x <= that.mailSel.length - 1; x++) {
                if (strMails != "") {
                    strMails = strMails + "|";
                }
                strMails = strMails + that.mailSel[x];
            }

            console.log("MAIL SEL:", strMails);


            var strUsers = "";

            for (var y = 0; y <= that.usersSel.length - 1; y++) {
                if (strUsers != "") {
                    strUsers = strUsers + "|";
                }
                strUsers = strUsers + that.usersSel[y];
            }

            console.log("USERS SEL:", strUsers);






            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.setPagamento(
                that.pagamento,
                id_rec,
                v_token,
                undefined,
                false,
                v_choose2,
                strMails,
                strUsers
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setPagamento", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    that.$modal.hide('popupUsersNotifiche');


                    this.hideShowButtons(false);


                    window.table.ajax.reload();


                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },








        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);



            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamentoGo(
                ids,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamentoGo", res);

                try {

                    this.pagamento = res.data.Result;

                    this.mails = res.data.Mails;

                    this.users = res.data.Users;

                    if (res.data.MailsSelDef != null) {

                        var elements = res.data.MailsSelDef.split("|");

                        console.log("MAIL SEL: ", elements);


                    }


                    if (res.data.UsersSelDef != null) {

                        var elements2 = res.data.UsersSelDef.split("|");

                        console.log("USERS SEL: ", elements2);


                    }


                    setTimeout(() => {

                        $(".contpopuppagamento").show();

                        this.$modal.show('popupPagamento');

                        if (res.data.Result.is_child_pag == "N") {

                            console.log("pagamento");


                            that.pagamento.importo_pagato = res.data.Result.importo_rimanente;

                            var today = new Date();
                            var dd = String(today.getDate()).padStart(2, '0');
                            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                            var yyyy = today.getFullYear();

                            console.log("giorno: ", dd);
                            console.log("mese: ", mm);
                            console.log("anno: ", yyyy);

                            var v_data = yyyy + "-" + mm + "-" + dd;



                            that.pagamento.data_pagamento = v_data;



                        }

                    }, 300);




                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncInsPagamento: async function () {


            $(".contpopuppagamento").show();

            this.$modal.show('popupPagamento');




        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curAzienda = azienda.companyid;

            window.$cookies.set("sel_filter_azienda_pagamenti", azienda.companyid, "9y");


            this.hideShowButtons(false);

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;


            window.table.ajax.reload();

        },

        manageClickPagamenti: async function (pagamento) {

            console.log("AZI SEL: ", pagamento);

            this.sheetPagamenti = false;

            window.curPagamento = pagamento.id;

            this.hideShowButtons(false);

            window.table.ajax.reload();

        },

        manageClickScaduti: async function (scaduto) {

            console.log("AZI SEL: ", scaduto);

            this.sheetScaduti = false;

            window.curScaduto = scaduto.id;

            this.hideShowButtons(false);

            window.table.ajax.reload();

        },

        downloadWithAxios(url, title) {
            axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
                .then((response) => {
                    this.forceFileDownload(response, title)
                })
                .catch(() => console.log('error occured'))
        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "public/api/alertduedate/" + nome_metodo;

        },



        getUrlDomainBtn: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + nome_metodo;

        },



        setupGrid: function () {

            // var that = this;
            // var pathLocal = that.$withBase('/');

            var v_token = window.$cookies.get("token");
            var v_su = window.$cookies.get("a");

            var filtri = "";

            if (window.curChooseSubject == "A") // azienda
            {

                if ((v_su == "Y") || (v_su == "S")) {

                    //     filtri = `<img src='/alertduedate/img/refresh-alertduedate.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/alertduedate/img/owners.png' style='border:0!important;' class='selproprietario' title='Utenti' /><img src='/alertduedate/img/aziende-alertduedate.png' style='border:0!important;' class='selazienda' title='Aziende' /><div style="transform: translateY(-1px);margin-left:7px" title='Tipo Pagamento'>
                    // <ion-select   data-id="0"  class="seltipopagamento" interface="action-sheet" placeholder=" " title='Tipo Pagamento'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='1'>Pagato</ion-select-option><ion-select-option  value='2'>Pagato Parzialmente</ion-select-option><ion-select-option  value='3'>Non Pagato</ion-select-option></div><div style="transform: translateY(-1px);margin-left:7px" title='Filtro Scaduti'>
                    // <ion-select   data-id="0"  class="selscaduti" interface="action-sheet" placeholder=" " title='Scaduti'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='1'>Scaduti</ion-select-option><ion-select-option  value='2'>Non Scaduti</ion-select-option></div><img src='/alertduedate/img/tipologie-alertduedate.png' style='border:0!important;' class='seltipologia' title='Tipologia' />`;


                    filtri = `<img src='/alertduedate/img/refresh-alertduedate.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/alertduedate/img/aziende-alertduedate.png' style='border:0!important;' class='selazienda' title='Aziende' /><img src='/alertduedate/img/alertduedate-filtri-disattivi.png' style='border:0!important;' class='selfiltri' title='Filtri' />`;


                }
                else {

                //     filtri = `<img src='/alertduedate/img/refresh-alertduedate.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/alertduedate/img/aziende-alertduedate.png' style='border:0!important;' class='selazienda' title='Aziende' /><div style="transform: translateY(-1px);margin-left:7px" title='Tipo Pagamento'>
                // <ion-select   data-id="0"  class="seltipopagamento" interface="action-sheet" placeholder=" " title='Tipo Pagamento'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='1'>Pagato</ion-select-option><ion-select-option  value='2'>Pagato Parzialmente</ion-select-option><ion-select-option  value='3'>Non Pagato</ion-select-option></div><div style="transform: translateY(-1px);margin-left:7px" title='Scaduti'>
                // <ion-select   data-id="0"  class="selscaduti" interface="action-sheet" placeholder=" " title='Scaduti'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='1'>Scaduti</ion-select-option><ion-select-option  value='2'>Non Scaduti</ion-select-option></div><img src='/alertduedate/img/tipologie-alertduedate.png' style='border:0!important;' class='seltipologia' title='Tipologia' />`;

                    filtri = `<img src='/alertduedate/img/refresh-alertduedate.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/alertduedate/img/aziende-alertduedate.png' style='border:0!important;' class='selazienda' title='Aziende' /><img src='/alertduedate/img/alertduedate-filtri-disattivi.png' style='border:0!important;' class='selfiltri' title='Filtri' />`;


                }



            }

            if (window.curChooseSubject == "P") // privato
            {

                if ((v_su == "Y") || (v_su == "S")) {

                    filtri = `<img src='/alertduedate/img/refresh-alertduedate.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/alertduedate/img/owners.png' style='border:0!important;' class='selproprietario' title='Utenti' /><div style="transform: translateY(-1px);margin-left:7px" title='Tipo Pagamento'>
                <ion-select   data-id="0"  class="seltipopagamento" interface="action-sheet" placeholder=" " title='Tipo Pagamento'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='1'>Pagato</ion-select-option><ion-select-option  value='2'>Pagato Parzialmente</ion-select-option><ion-select-option  value='3'>Non Pagato</ion-select-option></div><div style="transform: translateY(-1px);margin-left:7px" title='Scaduti'>
                <ion-select   data-id="0"  class="selscaduti" interface="action-sheet" placeholder=" " title='Scaduti'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='1'>Scaduti</ion-select-option><ion-select-option  value='2'>Non Scaduti</ion-select-option></div><img src='/alertduedate/img/tipologie-alertduedate.png' style='border:0!important;' class='seltipologia' title='Tipologia' />`;


                }
                else {

                    filtri = `<img src='/alertduedate/img/refresh-alertduedate.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><div style="transform: translateY(-1px);margin-left:7px" title='Tipo Pagamento'>
                <ion-select   data-id="0"  class="seltipopagamento" interface="action-sheet" placeholder=" " title='Tipo Pagamento'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='1'>Pagato</ion-select-option><ion-select-option  value='2'>Pagato Parzialmente</ion-select-option><ion-select-option  value='3'>Non Pagato</ion-select-option></div><div style="transform: translateY(-1px);margin-left:7px" title='Scaduti'>
                <ion-select   data-id="0"  class="selscaduti" interface="action-sheet" placeholder=" " title='Filtro Scaduti'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='1'>Scaduti</ion-select-option><ion-select-option  value='2'>Non Scaduti</ion-select-option></div><img src='/alertduedate/img/tipologie-alertduedate.png' style='border:0!important;' class='seltipologia' title='Tipologia' />`;


                }


            }

            console.log(filtri);

            var pointerVue = this;

            const date = new Date().toJSON().slice(0, 10)
            console.log(date) //2015-07-23

            // var $futureTime = date;

            $(document).ready(function () {

                window.id_par_sel = 0;

                var dataCol = [{
                    data: 'sel_row',
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '1%',
                },

                {
                    data: 'newid' /* 'DT_RowIndex' */,
                    orderable: false,
                    searchable: false,
                    name: '',
                    width: '0.5%',

                },

                {
                    data: 'nome_tipologia',
                    orderable: true,
                    name: 'nome_tipologia',
                    width: '2%'
                },

                {
                    data: 'descrizione',
                    orderable: true,
                    name: 'descrizione',
                    width: '10%'
                },

                {
                    data: 'fld_name',
                    orderable: false,
                    name: 'fld_name',
                    width: '4%'
                },

                {
                    data: 'nome_utente',
                    orderable: false,
                    name: 'nome_utente',
                    width: '4%'
                },

                {
                    data: 'created_at',
                    orderable: true,
                    filtrable: false,
                    name: 'created_at',
                    width: '3%'
                },

                {
                    data: 'data_scadenza',
                    orderable: true,
                    name: 'data_scadenza',
                    width: '3%'
                },

                {
                    data: 'scaduto',
                    orderable: false,
                    name: 'scaduto',
                    width: '1%'
                },



                {
                    data: 'importo',
                    orderable: false,
                    name: 'importo',
                    width: '2%'
                },

                {
                    data: 'importo_pagato',
                    orderable: false,
                    name: 'importo_pagato',
                    width: '2%'
                },

                {
                    data: 'data_pagamento',
                    orderable: false,
                    name: 'data_pagamento',
                    width: '3%'
                },

                {
                    data: 'importo_rimanente',
                    orderable: false,
                    name: 'importo_rimanente',
                    width: '2%'
                },

                {
                    data: 'nome_stato',
                    orderable: false,
                    name: 'nome_stato',
                    width: '1%'
                },

                {
                    data: 'allegato',
                    orderable: false,
                    name: 'allegato',
                    width: '1%'
                },

                {
                    data: 'pagamento_dettagli',
                    orderable: false,
                    name: 'pagamento_dettagli',
                    width: '3%'
                },

                {
                    width: '2%',
                    title: '',
                    target: 0,
                    className: 'treegrid-control',
                    data: function (item) {
                        if (item.children) {
                            return '<span class="expand">  + </span>';
                        }
                        return '';
                    }
                },

                ];

                window.table = $('#myTable').DataTable({

                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": 500,

                    processing: false,
                    paging: true,
                    serverSide: true,

                    'treeGrid': {
                        'left': 50,
                        'expandIcon': '<span>+</span>',
                        'collapseIcon': '<span>-</span>'
                    },


                    ajax: {
                        url: pointerVue.getUrlDomain("getPagamenti") + '?v_user=' + v_token,
                        data: function (valori) {

                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curAzienda = window.curAzienda;
                            valori.curPagamento = window.curPagamento;
                            valori.curScaduto = window.curScaduto;

                            valori.curSrcText = window.curSrcText;

                            valori.curTipologia = window.curTipologia;

                            valori.curChooseSubject = window.curChooseSubject;

                            valori.propid = window.propid;


                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label><select name="myTable_length" style="display:none!important" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select></label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log("SETTINGS: ", oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });

                    },

                    columns: dataCol,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();




                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);
                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {


                            pointerVue.$root.$children[0].showProgress = false;


                        }, 700);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table.ajax.reload();
                                    }
                                });

                            });

                        });


                        $(".downloadspinner").hide();

                        $('.p_importo, .p_importo_pagato, .p_importo_rimanente').number(true, 2, ',', '.');

                        var importo = 0;
                        $(".p_importo").each(function () {
                            importo = importo + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var importo_pagato = 0;
                        $(".p_importo_pagato").each(function () {
                            importo_pagato = importo_pagato + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var importo_rimanente = 0;
                        $(".p_importo_rimanente").each(function () {
                            importo_rimanente = importo_rimanente + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        $("#v_tot_importo").text(importo.toString().replace(".", ","));
                        $('#v_tot_importo').number(true, 2, ',', '.');

                        $("#v_tot_importo_pagato").text(importo_pagato.toString().replace(".", ","));
                        $('#v_tot_importo_pagato').number(true, 2, ',', '.');

                        $("#v_tot_importo_rimanente").text(importo_rimanente.toString().replace(".", ","));
                        $('#v_tot_importo_rimanente').number(true, 2, ',', '.');

                    }

                });

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");


                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Pagamenti</div>
                    ` + filtri + `

                </div>
               

            `);

                console.log("EVENTO PAGAMENTI " + window.pagamentiAddedEvents);



                $(".selfiltri").on("click", function (opt) {
                    console.log(opt);



                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });


                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });



                if (window.pagamentiAddedEvents === undefined) {




                    $('body').on('click', '.paginate_button', function () {
                        $(".spin").show();

                    });

                    $('body').on('click', '.refreshButton', function () {


                        pointerVue.showSpinner = true;
                        pointerVue.$root.$children[0].showProgress = true;

                        window.table.ajax.reload();

                    });

                    $('body').on('click', '.cont_pagamento tr', function () {


                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");

                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);

                            pointerVue.hideShowButtonsSelRow();


                        }





                    });

                }

                $('body').on('click', '.selproprietario', function () {

                    console.log(("EVENTO CLICK SELPROPRIETARIO"));

                    pointerVue.sheetProprietari = true;

                });


                $('body').on('click', '.selazienda', function () {

                    console.log(("EVENTO CLICK SELAZIENDA"));

                    pointerVue.sheetAziende = true;

                });

                $('body').on('click', '.seltipologia', function () {


                    console.log("ok");

                    pointerVue.sheetTipologia = true;

                });



                $('body').on('click', '.selaggiorna', function () {

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;


                    window.table.ajax.reload();
                    pointerVue.hideShowButtons(false);

                });

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".seltipopagamento").on("ionChange", function (opt) {
                        console.log(opt);

                        pointerVue.showSpinner = true;
                        pointerVue.$root.$children[0].showProgress = true;

                        window.curPagamento = this.value;

                        window.table.ajax.reload();
                        pointerVue.hideShowButtons(false);

                    });

                    $(".selscaduti").on("ionChange", function (opt) {
                        console.log(opt);

                        pointerVue.showSpinner = true;
                        pointerVue.$root.$children[0].showProgress = true;

                        window.curScaduto = this.value;

                        window.table.ajax.reload();
                        pointerVue.hideShowButtons(false);

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.pagamentiAddedEvents = true;

            });

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/add-alertduedate.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit-alertduedate.png",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-alertduedate.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Copy",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/copia-alertduedate.png",
                        // class: "inverted",
                        link: "/info",
                        id: "btn_copy",
                        disabled: false,
                        width: 30
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        saveProfile: async function () {
            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {
                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {
                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Your data has been saved"
                });

                /*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;
            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {
                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {
                console.log("Profile: ", this.profile);
                this.saveProfile();
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        deleteRow: async function () {

            var v_token = window.$cookies.get("token");


            var that = this;

            that.$swal
                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare l'elemento selezionato ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_alertduedate.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_alertduedate.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }
                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;


                        var response = await apipagamento.deleteMultiPagamentoGo(
                            ids,
                            v_token

                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteMultiPagamentoGo", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                window.table.ajax.reload();

                                that.hideShowButtons(false);

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("ERrori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

        downAttach: async function (id) {

            console.log("ID", id);

        },


        hideShowButtonsSelRow: function () {

            var tot = 0;
            var tot_open_status = 0;
            var val_url = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;


                    val_url = $(this).attr("v-url");

                    var val_status_id = $(this).attr("status-id");

                    if (val_status_id == "Open") // stato aperto
                    {

                        tot_open_status = tot_open_status + 1;

                    }



                }

            });

            console.log("TOT SEL: ", tot);
            console.log("TOT SEL OPEN STATUS: ", tot_open_status);

            setTimeout(() => {

                var gruppo_utente = JSON.parse(localStorage.getItem('al_info_global')).gruppo_utente;

                var elems = [];

                if (gruppo_utente != null) {
                    elems = gruppo_utente.split(",");

                }

                var pulsantis = [];

                pulsantis.push(

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/add-alertduedate.png",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        title: "Aggiungi",
                        width: 30
                    }

                );

                if (tot > 0) {


                    if (!elems.includes("17")) // commercialista
                    {

                        pulsantis.push(

                            {
                                text: "",
                                icon: "mdi-delete",
                                image: "https://app.emtool.eu/public/_lib/img/delete-alertduedate.png",
                                //class: "inverted",
                                link: "/delete",
                                id: "btn_delete",
                                disabled: false,
                                title: "Elimina",
                                width: 30
                            }


                        );


                    }



                }


                if (tot == 1) {

                    if (elems.includes("17")) {

                        pulsantis.push(

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/edit-alertduedate.png",
                                //  class: "inverted",
                                link: "/info",
                                id: "btn_edit",
                                disabled: false,
                                title: "Modifica",
                                width: 30

                            }

                        );



                    }
                    else {

                        pulsantis.push(

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/edit-alertduedate.png",
                                // class: "inverted",
                                link: "/info",
                                id: "btn_edit",
                                disabled: false,
                                title: "Modifica",
                                width: 30

                            }

                        );


                        pulsantis.push(

                            {
                                text: "Copy",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/copia-alertduedate.png",
                                //  class: "inverted",
                                link: "/info",
                                id: "btn_copy",
                                disabled: false,
                                title: "Copia",
                                width: 30

                            }

                        );

                        pulsantis.push(

                            {
                                text: "Copy",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/ins_edit_pagamenti_alertduedate.png",
                                //class: "inverted",
                                link: "/info",
                                id: "btn_ins_edit_payment",
                                disabled: false,
                                title: "Pagamento",
                                width: 25
                            }

                        );

                    }


                }



                if (tot > 1) {


                    pulsantis.push(

                        {
                            text: "Copy",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/calculator-alertduedate.png",
                            //class: "inverted",
                            link: "/info",
                            id: "btn_calculator",
                            disabled: false,
                            title: "Calcolatrice",
                            width: 22
                        }

                    );


                }



                if (tot == 1) {

                    if (val_url != "") {


                        pulsantis.push(

                            {
                                text: "Copy",
                                icon: "mdi-home-circle",
                                image: this.getUrlDomainBtn("public/_lib/img/view-alertduedate.png"),
                                link: "/info",
                                id: "btn_vedi_doc",
                                disabled: false,
                                title: "Vedi Allegato",
                                width: 35
                            }

                        );



                    }


                }





                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },






        hideShowButtons: async function (v_enable) {

            //console.log("hideShowButtons");

            setTimeout(() => {

                var gruppo_utente = JSON.parse(localStorage.getItem('al_info_global')).gruppo_utente;

                var elems = [];

                if (gruppo_utente != null) {
                    elems = gruppo_utente.split(",");

                }

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/add-alertduedate.png",
                            // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            title: "Aggiungi",
                            width: 30

                        },

                        // {
                        //     text: "Indietro",
                        //     icon: "mdi-arrow-left",
                        //     image: "https://app.emtool.eu/public/_lib/img/back-alertduedate.png",
                        //     link: "/confOrariChoice",
                        //     id: "btn_back",
                        //     disabled: false,
                        //     title: "Indietro",
                        //     width: 35
                        // },


                    ];




                }

                if (v_enable == true) {

                    if (elems.includes("17")) // commercialista
                    {

                        pulsantis = [

                            {
                                text: "Add",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/add-alertduedate.png",
                                // class: "inverted",
                                link: "/settings",
                                id: "btn_add",
                                disabled: false,
                                title: "Aggiungi",
                                width: 30

                            },

                            // {
                            //     text: "Indietro",
                            //     icon: "mdi-arrow-left",
                            //     image: "https://app.emtool.eu/public/_lib/img/back-alertduedate.png",
                            //     link: "/confOrariChoice",
                            //     id: "btn_back",
                            //     disabled: false,
                            //     title: "Indietro",
                            //     width: 35
                            // },


                        ];

                    } else {

                        pulsantis = [

                            {
                                text: "Add",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/add-alertduedate.png",
                                // class: "inverted",
                                link: "/settings",
                                id: "btn_add",
                                disabled: false,
                                title: "Aggiungi",
                                width: 30

                            },

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/edit-alertduedate.png",
                                //class: "inverted",
                                link: "/info",
                                id: "btn_edit",
                                disabled: false,
                                title: "Modifica",
                                width: 30

                            },

                            {
                                text: "Delete",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/delete-alertduedate.png",
                                // class: "inverted",
                                link: "/info",
                                id: "btn_delete",
                                disabled: false,
                                title: "Elimina",
                                width: 30

                            },

                            {
                                text: "Copy",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/copia-alertduedate.png",
                                // class: "inverted",
                                link: "/info",
                                id: "btn_copy",
                                disabled: false,
                                title: "Copia",
                                width: 30

                            },

                            // {
                            //     text: "Indietro",
                            //     icon: "mdi-arrow-left",
                            //     image: "https://app.emtool.eu/public/_lib/img/back-alertduedate.png",
                            //     link: "/confOrariChoice",
                            //     id: "btn_back",
                            //     disabled: false,
                            //     title: "Indietro",
                            //     width: 35
                            // },


                        ];

                    }

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        }

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.selfiltri {
    max-width: 23px;
    min-height: 23px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}




canvas {
    border: 1px solid #c7c4a9;
    margin-top: 10px;
}

#container-view-pdf {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cont_pagamento .cl_descrizione2 {}

.cont_pagamento .cl_azienda2 {}

.cont_pagamento .cl_utente2 {}

.cont_pagamento .cl_created_at2 {}

.cont_pagamento .cl_data_scadenza2 {}

.cont_pagamento .data_pagamento {
    padding-left: 24px
}


.cont_pagamento .nome_stato {
    text-align: center !important
}


.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #45b7e9;

}

.pay a {
    color: #45b7e9;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #45b7e9;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.cont_pagamento .p_importo_pagato {
    text-align: right !important;
}

.cont_pagamento .text_title {
    z-index: 1;
}

.cont_pagamento .cont_buttons .text_title {
    min-width: 40px;

}

.cont_pagamento .text_title {
    min-width: 40px !important;
}

.cont_pagamento .cont_buttons ion-item {
    transform: translate(-16px, 2px);
}

.cont_pagamento .cont_buttons {
    min-width: 102px;
}



.selproprietario {
    max-width: 22px;
    min-height: 22px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}



.selazienda {
    max-width: 27px;
    min-height: 27px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.seltipopagamento {
    max-width: 25px;
    min-height: 25px;
    margin-left: 2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 29px;
    background-repeat: no-repeat;
    background-image: url(/alertduedate/img/tipo-pagamento-alertduedate.png);
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: -1px;
}

.selscaduti {
    max-width: 25px;
    min-height: 25px;
    margin-left: 2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 29px;
    background-repeat: no-repeat;
    background-image: url(/alertduedate/img/scaduti-alertduedate.png);
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 1px;
}

.selaggiorna {
    max-width: 25px;
    min-height: 25px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /*   background-image: url(/alertduedate/img/FiltroAzienda.png); */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}


.seltipologia {
    max-width: 30px;
    min-height: 30px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 0px;
}

.itemProprietario {
    margin-left: 15px;
}
</style>

<style>
.cont_pagamento #myTable_wrapper {
    min-width: initial;
    min-width: 1600px;
    max-width: 1600px;
    width: 1600px;
}

.cont_pagamento #myTable {
    margin-right: 3px;
    width: 1500px !important;
}

.cont_pagamento .nome_parente {
    min-width: 200px;
}

.cont_pagamento .inserito {
    min-width: 70px;
}

.cont_pagamento .osp_assoc {
    min-width: 70px;
    display: flex;
    justify-content: center;
}

.cont_pagamento .mail {
    min-width: 200px;
}

.cont_pagamento td:first-child {
    padding: 12px;
}

.cont_pagamento #myTable_filter {
    top: 66px;
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #45b7e9;
}

.cont_pagamento .val_status {
    width: 10px !important;
}


@media screen and (min-width: 1024px) {

    .cont_pagamento table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
        visibility: hidden !important;
    }

    .tdPayments {
        visibility: hidden;
        max-width: 2px !important;
        overflow: hidden !important;

    }

    .tblPayments {
        visibility: hidden;

        max-height: 1px;
        zoom: 1;
        /* background: blueviolet; */

        display: inline !important;
        max-width: 1px !important;

    }

}

.spazio {
    padding-left: 24px
}

.col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
}

.align_close_sheet {
    top: 23px !important
}

.cont_pagamento .cl_checkall {
    padding-left: 12px;
    padding-top: 2px;
}

.cont_pagamento .checkall {
    width: 13px;
    height: 13px;
}

.cont_pagamento .cl_num {
    margin-left: -3px;
}

.headerSheetProprietari {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.color_red {
    color: red;
}


.cont_pagamento .p_importo_rimanente {
    text-align: right !important;
}

.cont_pagamento .p_importo {
    text-align: right !important;
}




@media screen and (max-width: 768px) {


    .align_close_sheet {
        top: 18px !important
    }

    .spazio {
        padding-left: 12px !important
    }

    .div_n {
        padding-left: 20px;
    }

    .cont_pagamento .nome_parente {
        min-width: initial;
        word-break: break-all;
        max-width: 120px;
    }

    .cont_pagamento .inserito {
        min-width: initial;
    }

    .cont_pagamento .osp_assoc {
        min-width: initial;
        display: flex;
        justify-content: center;
    }

    .cont_pagamento .mail {
        min-width: initial;
    }

    .cont_pagamento #myTable_wrapper {
        min-width: initial;
        min-width: 100vw;
        max-width: 100vw;
        width: 100vw;
        overflow-x: auto !important;
    }

    .cont_pagamento #myTable_filter {
        top: 58px;
        position: fixed;
        left: 0px;
        min-width: 100%;
        z-index: 3;
    }



    /* .cont_pagamento #myTable {
        max-width: 99vw;
        width: 99vw !important;
    } */

    .cont_pagamento #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;
    }

    .cont_pagamento .cl_descrizione2 {
        width: 126px;
    }


    .cont_pagamento table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
        left: 10px !important;
    }

    .treegrid-control {
        display: none;
    }

    .tblPayments div {

        padding: 5px;

    }

    .col_footer {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        color: white;
    }

    .col_footer_right {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        text-align: right;
        color: white;
    }

    .seltipopagamento {
        max-width: 25px;
        min-height: 25px;
        margin-left: 2px;
        color: black !important;
        padding: 0px 0px;
        min-width: 14px !important;
        text-indent: -119px;
        background-size: 29px;
        background-repeat: no-repeat;
        background-image: url(/alertduedate/img/filter_type_payment2.png);
        z-index: 2;
        border: none !important;
        cursor: pointer;
        margin-top: -1px;
    }

    .selscaduti {
        max-width: 25px;
        min-height: 25px;
        margin-left: 2px;
        color: black !important;
        padding: 0px 0px;
        min-width: 14px !important;
        text-indent: -119px;
        background-size: 29px;
        background-repeat: no-repeat;
        background-image: url(/alertduedate/img/filter_expired.png);
        z-index: 2;
        border: none !important;
        cursor: pointer;
        margin-top: 1px;
    }

    .cont_pagamento .cl_num {
        margin-left: -4px !important;
    }

    .cont_pagamento .cl_checkall {
        padding-left: 29px;
        padding-top: 2px;
    }

    .cont_pagamento .cl_azienda2 {
        width: 100px;
    }

    .cont_pagamento .cl_utente2 {
        width: 100px;
    }

    .cont_pagamento .cl_created_at2 {
        width: 100px;
    }

    .cont_pagamento .cl_data_scadenza2 {
        width: 100px;
    }

    .cont_pagamento .scaduto2 {
        text-align: center !important
    }

    .cont_pagamento .img_scaduto2 {
        transform: translateY(0px);
        width: 10px;
    }

    .cont_pagamento .p_importo {
        text-align: right !important;
        width: 90px;
    }

    .cont_pagamento .p_importo_pagato {
        text-align: right !important;
        width: 90px;
    }

    .cont_pagamento .data_pagamento {
        padding-left: 24px;
        width: 90px;
    }

    .cont_pagamento .p_importo_rimanente {
        text-align: right !important;
        width: 90px;
    }

    .cont_pagamento .nome_stato {
        text-align: center !important;
        width: 50px;
    }


}

/* .scaduto {
    min-width: 56px;
    justify-content: center;
} */

.scaduto2 {
    text-align: center !important
}

.img_scaduto2 {
    transform: translateY(0px);
    width: 10px;
}


.tipologia {
    justify-content: center;
    display: flex;
}


.tipologia2 {
    justify-content: center;
    display: flex;
}




.valori {
    justify-content: right;
    display: flex;
}

.invio_mail {
    min-width: 56px;
    justify-content: center;
}

.cont_pagamento .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width: 80px
}


.cont_pagamento .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}



@media screen and (max-width: 1024px) {

    /* .scaduto,
    .tipologia,
    .valori,
    .valori_importo_pagato {
        display: inline-flex;
        height: 20px;
        align-items: center;
        transform: translateY(1px);
        justify-content: flex-start !important;

    } */

    .tipologia2 {
        width: 56px;
    }

    .invio_mail {
        display: inline-flex;
        height: 20px;
        align-items: center;
        transform: translateY(1px);
        justify-content: flex-start !important;
        padding-left: 4px;

    }

    canvas {
        border: 1px solid #c7c4a9;
        margin-top: 10px;
        margin-left: 573px;
    }


}

.diplay_plus {
    visibility: hidden;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetPagamenti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetScaduti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.cont_pagamento .closeSheet {
    position: absolute;
    right: 10px;
    top: 14px !important;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemPagamento {
    margin-left: 15px;
}

.itemScaduto {
    margin-left: 15px;
}

img {
    border-image-width: 0px !important;
    border: none !important;
}

.v-application .text-center {

    overflow: auto;
}

.expand,
.treegrid-control span {
    font-size: 28px;
}

td.treegrid-control {
    min-height: 72px;
}

.treegrid-control,
.treegrid-control-open span {
    font-size: 34px;
    cursor: pointer;
}

.col_border {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    text-align: center !important;
    border-top: 1px solid black !important
}

.tr {
    display: table-row;
}

.td {
    display: table-cell;
}
</style>
