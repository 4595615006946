<template>
    <v-container class="w-container v-overflow" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color: #45b7e9">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>

                <v-col cols="12" md="12">

                    <v-textarea v-model="pagamento.descrizione" label="Nome / Descrizione" :disabled="disField"
                        maxlength="200"></v-textarea>

                </v-col>

            </v-row>

            <v-row v-if="viewRowAzienda">

                <v-col cols="12" md="12">
                    <div id="cont_companyid" style="cursor:pointer">
                        <ion-item
                            style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                            <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                                position="floating">Azienda </ion-label>

                            <ion-select id="companyid" name="companyid" class='companyid' cancel-Text="Chiudi"
                                done-Text="" :value="pagamento.companyid" interface="action-sheet" disabled readonly>

                                <ion-select-option v-for="item in aziende" :key="item.companyid"
                                    :value='item.companyid'> {{ item.fld_name }}</ion-select-option>

                            </ion-select>
                        </ion-item>
                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="pagamento.data_scadenza" label="Data Scadenza" persistent-hint
                                readonly v-on="on" required :rules="dataScadenzaRules" :disabled="disField">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="pagamento.data_scadenza" no-title @input="showPicker = false"
                            locale="it"></v-date-picker>
                    </v-menu>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_id_tipologia" style="cursor:pointer">

                        <ion-item id="item_id_tipologia" style="min-width:200px;transform: translateX(-6px);">
                            <ion-label style="font-size:12px;color:gray!important;opacity:1" position="floating">
                                Tipologia </ion-label>
                            <ion-select id="tipologia" name="tipologia" class='tipologia' cancel-Text="Chiudi"
                                done-Text="" :value="pagamento.id_tipologia" interface="action-sheet" disabled readonly>
                                <ion-select-option v-for="item in tipologie" :key="item.id" :value='item.id'> {{
                                    item.nome
                                    }}</ion-select-option>
                            </ion-select>
                        </ion-item>

                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="d_importo"
                        class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                        <div class="v-input__control">
                            <div class="v-input__slot">
                                <div class="v-text-field__slot"><label for="input-29"
                                        class="v-label v-label--active theme--light"
                                        style="left: 0px; right: auto; position: absolute;">Da Pagare</label>

                                    <vue-autonumeric v-model="pagamento.importo" :disabled="disField" :options="{
                                        digitGroupSeparator: '.',
                                        decimalCharacter: ',',
                                        decimalCharacterAlternative: '.',
                                        currencySymbol: '\u00a0€',
                                        currencySymbolPlacement: 's',
                                        roundingMethod: 'U',
                                        minimumValue: '0'
                                    }"></vue-autonumeric>

                                </div>
                            </div>

                            <div class="v-text-field__details">
                                <div class="v-messages theme--light">
                                    <div class="v-messages__wrapper"></div>
                                </div>
                            </div>

                        </div>
                    </div>



                </v-col>

            </v-row>





            <v-row>

                <v-col cols="12" md="12">

                    <!-- <v-file-input label="Allegato" prepend-icon="" v-model="currentFile" :disabled="disField">
                    </v-file-input> -->

                    <span class="files">
                        <label style="margin-left:11px;font-size:11px">Allegato</label>
                        <input type="file" id="input-file-logo" name="logo" accept=".png,.jpg,.jpeg,.pdf"
                            class="dropify" data-max-width="6610" data-max-height="6200" />
                    </span>


                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-checkbox label="Invio Mail" v-model="pagamento.invia_mail" true-value="S" false-value="N"
                        :disabled="disField"></v-checkbox>

                </v-col>

            </v-row>

        </v-form>

        <div class="contaziende">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                Aziende
                                <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                                    </v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid"
                                        @click="manageClickAziende(item)">
                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                        <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                        <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline
                                        </v-icon>
                                    </v-list-item>

                                    <div style="min-height:170px;height:170px">&nbsp;</div>

                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="conttipologie">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetTipologie" class="vtipologiesheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetTipologie" fixed style="position:fixed;">
                                Tipologie
                                <v-btn class='closeSheet' icon @click="sheetTipologie = !sheetTipologie">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchTipologie"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredTipologie" :key="item.id"
                                        @click="manageClickTipologie(item)">
                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                        <v-list-item-title class='itemTipologia'>{{ item.nome }}</v-list-item-title>
                                        <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline
                                        </v-icon>
                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

    </v-container>
</template>

<script>
import apipagamento from "../utils/pagamento/apipagamento";

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        this.$root.$children[0].checkLogout();


        console.log("PARAMS: ", this.$route.params.id);

        var pointerVue = this;

        var v_choose = window.$cookies.get("sel_tipo_soggetto_pagamento");

        pointerVue.viewRowAzienda = true;
        if (v_choose == "P") {
            pointerVue.viewRowAzienda = false;
        }

        //console.log("AZIENDE: ", window.$cookies.get("gen_settings"));

        this.cur_id = this.$route.params.id;

        this.setupButtons();

        this.$root.$children[0].initDivError("#d_importo", "Importo richiesto");
        this.$root.$children[0].initDivError("#d_pagato", "");
        this.$root.$children[0].initDivError("#d_importo_rimanente", "");



        // setTimeout(() => {
        //     this.loadTipologie();
        // }, 100);




        if (this.$route.params.id > 0) {

            this.titolo = "Copia Pagamento";

            setTimeout(() => {
                this.syncPagamento();
            }, 200);

        }

        // this.$root.$children[0].addClassError("#d_importo");

        this.pagamento.id_stato = "3";

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/pagamenti"
                    });
                    break;

                default:
                    break;
            }
        });

        $(document).ready(function () {

            $('body').on('click', '#cont_companyid', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetAziende = true;

                }


            });

            $('body').on('click', '#cont_id_tipologia', function (event) {
                console.log(event);

                if (!pointerVue.disField) {

                    pointerVue.sheetTipologie = true;

                }


            });


            setTimeout(() => {

                var oggDrop = $('.dropify').dropify({
                    messages: {
                        default: 'Drag',
                        replace: /* s */ '',
                        remove: 'Rimuovi',
                        error: 'File troppo grande'
                    }
                });

                $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
                $(".dropify-render").append("<img>");

                //console.log(oggDrop);

                oggDrop.on('dropify.afterClear', function (event, element) {

                    console.log(event, element);

                    pointerVue.attachRemove = true;

                });

            }, 100);

            $('#input-file-logo').on('change', function () {

                pointerVue.attachRemove = false;

            });


        });

    },

    components: {
        VueAutonumeric,
    },

    computed: {

        filteredAziende() {

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        filteredTipologie() {

            return _.orderBy(this.tipologie.filter(item => {
                if (!this.searchTipologie) return this.tipologie;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },

        solicitorsFeesDisplay: {
            get: function () {

                console.log("GET");

                if (this.pagamento.importo != undefined) {

                    return this.pagamento.importo.toFixed(2)

                } else {

                    return 0;

                }

            },
            set: function (newValue) {

                console.log("SET");

                if (newValue != "") {

                    console.log("SET VALUE; ", parseFloat(newValue).toFixed(2));

                    this.pagamento.importo = parseFloat(newValue).toFixed(2);

                }

            }
        }

    },

    data: () => ({

        attachRemove: false,

        viewRowAzienda: false,

        disField: false,
        disFieldRim: false,

        showImpRim: false,

        importoRimanente: 0,

        currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Aggiungi Pagamento",

        cur_id: 0,

        myValue: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        sheetTipologie: false,
        searchTipologie: "",

       // tipologie: [],

        tipologie2: [{
            "id": 1,
            "nome": "prova"
        }, {
            "id": 2,
            "nome": "prova2"
        }],

        showPicker: false,
        showPicker2: false,

        pagamento: {
            id_stato: "",
            descrizione: "",
        },
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Campo richiesto",
            (value) => (value && value.length >= 8 && value.length <= 10) || 'minimo 8 caratteri e massimo 10',
            (value) => (value && /[A-Z]/.test(value)) || 'Almeno un carattere maiuscolo',

        ],


        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        select: null,
        checkbox: false,

        aziende: JSON.parse(localStorage.getItem('al_info_global')).aziende,

        tipologie: JSON.parse(localStorage.getItem('al_info_global')).tipologie_pagamento,

        stati_pagamento: JSON.parse(localStorage.getItem('al_info_global')).stati_pagamento

    }),

    methods: {

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },


        formatDecimal(e) {

            //console.log("INPUT VALUE: ", e.target.value);

            // e.target.value = 3000;

            if (e.target.value != "") {

                var res = parseFloat(e.target.value);

                console.log("VAL: ", res.toFixed(2));

                e.target.value = res.toFixed(2);

                //e.target.value = e.target.value.toString().toFixed(2);

            }

        },

        checkImporto(e) {

            console.log("EVENT VALUE: ", e);

            if (e.target.value == "") {

                this.$root.$children[0].addClassError("#d_importo");

            }
            else {

                this.$root.$children[0].removeClassError("#d_importo");

            }


        },

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            this.pagamento.companyid = azienda.companyid;

        },

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.pagamento.id_tipologia = tipologia.id;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save-alertduedate.png",
                    // class: "inverted",
                    title: "Salva",
                    width: 30
                },
                {
                    text: "Indietro",
                    icon: "mdi-arrow-left",
                    image: "https://app.emtool.eu/public/_lib/img/back-alertduedate.png",
                    link: "/confOrariChoice",
                    //class: "inverted",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    width: 35
                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },


        validate() {

            var that = this;

            var v_choose = window.$cookies.get("sel_tipo_soggetto_pagamento");


            var errore = "";

            console.log("PAGAMENTO: ", that.pagamento);

            if (errore == "") {

                if (that.pagamento.descrizione == "") {
                    errore = "Nome / Descrizione valore richiesto";
                }

            }

            if (errore == "") {

                if (v_choose == "A") {

                    if (that.pagamento.companyid == "") {
                        errore = "Azienda valore richiesto";
                    }

                }

            }

            if (errore == "") {

                if (v_choose == "A") {

                    if (that.pagamento.companyid == null) {
                        errore = "Azienda valore richiesto";
                    }

                }

            }

            if (errore == "") {

                if (that.pagamento.data_scadenza == "") {
                    errore = "Data scadenza valore richiesto";
                }

            }

            if (errore == "") {

                if (that.pagamento.data_scadenza == null) {
                    errore = "Data scadenza valore richiesto";
                }

            }

            if (errore == "") {

                if (that.pagamento.id_tipologia == "") {
                    errore = "Tipologia valore richiesto";
                }

            }

            if (errore == "") {

                if (that.pagamento.importo == "") {
                    errore = "Da pagare valore richiesto";
                }

            }

            if (errore == "") {

                if (that.pagamento.importo == null) {
                    errore = "Da pagare valore richiesto";
                }

            }

            if (errore == "") {

                if (that.pagamento.importo_pagato != null) {

                    if (that.pagamento.importo_pagato != 0) {

                        if (that.pagamento.data_pagamento == null) {

                            errore = "Specificare la data di pagamento";

                        }

                    }

                }

            }

            if (that.importoRimanente == 0) {

                if (errore == "") {

                    if (that.pagamento.importo_pagato != null) {

                        if (parseFloat(that.pagamento.importo_pagato) > parseFloat(that.pagamento.importo)) {

                            errore = "Il valore del pagato non pùo essere superiore al valore da pagare";

                        }

                    }

                }

            } else {

                if (errore == "") {

                    if (that.pagamento.importo_pagato != null) {

                        if (parseFloat(that.pagamento.importo_pagato) > parseFloat(that.importoRimanente)) {

                            var v_rim = parseFloat(that.importoRimanente);

                            errore = "Il valore del pagato non pùo essere superiore al valore rimanente da pagare di € " + v_rim.toFixed(2);

                        }

                    }

                }

            }

            if (that.disField == true) {

                if (that.pagamento.data_pagamento == null) {

                    errore = "Specificare la data di pagamento";

                }

            }

            if (errore == "") {

                if (that.disField == true) {

                    if (that.pagamento.importo_pagato == null) {

                        errore = "Specificare l'importo pagato";

                    } else {
                        if (that.pagamento.importo_pagato == 0) {

                            errore = "Specificare l'importo pagato";

                        }
                    }

                }

            }

            console.log("ALL: ", $('#input-file-logo')[0].files.length);


            if (errore == "") {

                if ($('#input-file-logo')[0].files.length == 0) {

                    errore = "Allegato richiesto";

                }

            }

            if (errore == "") {

                if ($('#input-file-logo')[0].files.length > 0) {

                    var v_file = $('#input-file-logo')[0].files[0];

                    var elements = v_file.name.split('.');
                    var extension = elements[elements.length - 1];

                    if (extension.toString().toUpperCase() != "PNG") {
                        if (extension.toString().toUpperCase() != "JPG") {

                            if (extension.toString().toUpperCase() != "PDF") {

                                if (extension.toString().toUpperCase() != "JPEG") {

                                    errore = "Formati dell'immagine supportati PNG, JPG, JPEG, PDF";

                                }

                            }

                        }
                    }

                }

            }






            if (errore == "") {


                console.log("ok");

                that.saveData();


            }
            else {


                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });


            }





        },



        saveData: async function () {

            var v_token = window.$cookies.get("token");
            var v_choose2 = window.$cookies.get("sel_tipo_soggetto_pagamento");

            var that = this;

            console.log("TOKEN ", v_token);
            console.log("COPY PAGAMENTO ", that.pagamento);

            // console.log("AZIENDA ", );

            console.log("CUR_FILE: ", $('#input-file-logo')[0].files[0]);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.setPagamento(
                that.pagamento,
                0,
                v_token,
                $('#input-file-logo')[0].files[0],
                that.attachRemove,
                v_choose2
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setPagamento", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/pagamenti"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        syncPagamento: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getPagamentoGo(
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPagamentoGo", res);

                try {

                    this.pagamento = res.data.Result;

                    this.importoRimanente = res.data.Result.importo_rimanente;


                    if (res.data.Result.is_child_pag == "N") {

                        this.pagamento.importo_pagato = null;
                        this.pagamento.importo_rimanente = null;


                        this.dataScadenzaRules = [];
                        this.pagamento.data_scadenza = null;

                        this.importoRules = [];
                        this.pagamento.importo = null;
                        this.pagamento.importo_pagato = null;

                        this.pagamento.invia_mail = "S";

                        this.pagamento.id = 0;


                    }

                    if (res.data.Result.is_child_pag == "S") {


                        var imp_rim = this.pagamento.importo_rimanente;

                        if (imp_rim > 0) {

                            console.log("IMP PAG: ", this.pagamento.importo_pagato);

                            this.pagamento.importo_rimanente = imp_rim - this.pagamento.importo_pagato;
                            this.importoRimanente = imp_rim - this.pagamento.importo_pagato
                        }
                        else {

                            this.importoRimanente = imp_rim;

                        }


                        this.pagamento.importo_pagato = null;

                        var today = new Date();
                        var sDate = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, 0) + "-" + today.getDate().toString().padStart(2, 0);
                        this.pagamento.data_pagamento = sDate; // default data corrente

                        this.pagamento.id = 0;


                        this.disField = true;

                    }


                    if (res.data.Result.importo != null) {

                        this.$root.$children[0].setFieldActive("#d_importo");

                    }

                    if (res.data.Result.importo_pagato != null) {

                        this.$root.$children[0].setFieldActive("#d_pagato");

                    }

                    if (res.data.Result.importo_rimanente != null) {

                        this.$root.$children[0].setFieldActive("#d_importo_rimanente");


                    }

                    this.disFieldRim = true;


                    $("#item_id_tipologia").addClass("item-has-value");




                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        loadTipologie: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipagamento.getTipologie(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getTipologie", res);

                try {

                    this.tipologie = res.data.Result;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}
</style>
